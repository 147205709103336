<template>
  <div class="ci-filters py-4 px-4 pt-3">
    <CiHeading level="1">
      <span>{{ $t("filters.header") }}</span>

      <RdxButton
        v-if="!filtersAreOpen && countChosen"
        class="ci-filters__clear ml-2"
        :disabled="!countChosen"
        outline
        data-cy="clear-button"
        @click="clearFilters"
      >
        <RdxIcon class="ri-close-line"></RdxIcon>
        <span>
          {{ $t("filters.clear-button", { countChosen }) }}
        </span>
      </RdxButton>
    </CiHeading>
    <RdxIcon
      class="ci-filters__toggle rdx-icon--24"
      :class="toggleClasses"
      data-cy="toggle-button"
      @click="toggleFilters"
    />
    <div v-if="filtersAreOpen" class="ci-filters__content mt-3">
      <div class="row mb-2">
        <div class="col-6 col-xl-3">
          <RdxButtonGroup>
            <template v-for="(state, index) in options.states">
              <RdxButton
                :key="`filter-state-${index}`"
                :data-cy="`states-${state.value}-filter`"
                v-bind="isChosen('state', state)"
                @click="setChosen('state', state)"
              >
                {{ $t(`global.states.${state.value}`) }}
              </RdxButton>
            </template>
          </RdxButtonGroup>
        </div>
        <div class="col-6 col-xl-3">
          <!-- Price -->
          <RdxSelect
            v-model="chosen.availability"
            :options="options.availabilities"
            clearable
            vm-track-by="label"
            :placeholder="$t('filters.availability-placeholder')"
            data-cy="availabilities-filter"
          >
            <template #singleLabel="{ option }">
              {{ $t(`global.availabilities.${option.value}`) }}
            </template>
            <template #option="{ option }">
              {{ $t(`global.availabilities.${option.value}`) }}
            </template>
          </RdxSelect>
        </div>
        <div class="col-6 col-xl-3">
          <RdxSelect
            v-model="chosen.listing"
            :options="getListings"
            clearable
            vm-track-by="label"
            :placeholder="'Listing'"
            data-cy="listings-filter"
          >
            <template #singleLabel="{ option }">
              {{ option.label }}
            </template>
            <template #option="{ option }">
              {{ option.label }}
            </template>
          </RdxSelect>
        </div>
      </div>

      <div class="row">
        <div class="col-6 col-xl-3">
          <!-- Brand -->
          <RdxSelect
            v-model="chosen.brand"
            :options="options.brands"
            clearable
            vm-label="label"
            vm-track-by="label"
            :label="$t('filters.brand-input-label')"
            :placeholder="$t('filters.all-placeholder')"
            data-cy="brands-filter"
          />
        </div>
        <div class="col-6 col-xl-3">
          <!-- Model -->
          <RdxSelect
            v-model="chosen.model"
            :options="nameplatesByBrand"
            :disabled="disableModelFilter"
            clearable
            vm-label="label"
            vm-track-by="label"
            :label="$t('filters.model-input-label')"
            :placeholder="$t('filters.all-placeholder')"
            data-cy="models-filter"
          />
        </div>
        <div class="col-6 col-xl-3">
          <!-- Engine -->
          <RdxSelect
            v-model="chosen.engine"
            :options="options.engines"
            clearable
            vm-track-by="label"
            :label="$t('filters.engine-input-label')"
            :placeholder="$t('filters.all-placeholder')"
            data-cy="engines-filter"
          >
            <template #singleLabel="{ option }">
              {{ $t(`global.engines.${option.value}`) }}
            </template>
            <template #option="{ option }">
              {{ $t(`global.engines.${option.value}`) }}
            </template>
          </RdxSelect>
        </div>
        <div class="col-6 col-xl-3">
          <!-- Color -->
          <RdxSelect
            v-model="chosen.color"
            :options="options.colors"
            clearable
            vm-label="label"
            vm-track-by="label"
            :label="$t('filters.colour-input-label')"
            :placeholder="$t('filters.all-placeholder')"
            data-cy="colors-filter"
          >
            <template #singleLabel="{ option }">
              {{ option.label }}
            </template>
            <template #option="{ option }">
              {{ option.label }}
            </template>
          </RdxSelect>
        </div>
      </div>

      <div class="row">
        <div class="col-6 col-xl-3">
          <!-- Year -->
          <RdxInputGroup :label="$t('filters.year-input-label')">
            <!-- Year - from -->
            <RdxSelect
              v-model="chosen.yearFrom"
              :options="options.years.from"
              clearable
              vm-label="label"
              vm-track-by="label"
              :placeholder="$t('filters.from-placeholder')"
              data-cy="years-from-filter"
            />
            <!-- Year - to -->
            <RdxSelect
              v-model="chosen.yearTo"
              :options="options.years.to"
              clearable
              vm-label="label"
              vm-track-by="label"
              :placeholder="$t('filters.to-placeholder')"
              data-cy="years-to-filter"
            />
          </RdxInputGroup>
        </div>
        <div class="col-6 col-xl-3">
          <!-- Mileage -->
          <RdxInputGroup :label="$t('filters.mileage-input-label')">
            <!-- Mileage - from -->
            <RdxSelect
              v-model="chosen.mileageFrom"
              :options="options.mileage.from"
              clearable
              vm-track-by="label"
              :placeholder="$t('filters.from-placeholder')"
              data-cy="mileage-from-filter"
              :custom-label="formatMileage"
            />
            <!-- Mileage - to -->
            <RdxSelect
              v-model="chosen.mileageTo"
              :options="options.mileage.to"
              clearable
              vm-track-by="label"
              type="number"
              step="25000"
              :placeholder="$t('filters.to-placeholder')"
              data-cy="mileage-to-filter"
              :custom-label="formatMileage"
            />
          </RdxInputGroup>
        </div>
        <div class="col-6 col-xl-3">
          <!-- Price -->
          <RdxInputGroup :label="$t('filters.price-input-label')">
            <!-- Price - from -->
            <RdxSelect
              v-model="chosen.priceFrom"
              :options="options.price.from"
              clearable
              vm-track-by="label"
              type="number"
              :placeholder="$t('filters.from-placeholder')"
              data-cy="prices-from-filter"
              :custom-label="formatPrice"
            />
            <!-- Price - to -->
            <RdxSelect
              v-model="chosen.priceTo"
              :options="options.price.to"
              clearable
              vm-track-by="label"
              type="number"
              :placeholder="$t('filters.to-placeholder')"
              data-cy="prices-to-filter"
              :custom-label="formatPrice"
            />
          </RdxInputGroup>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-9">
          <!-- Clear -->
          <RdxButton
            v-if="filtersAreOpen && countChosen"
            outline
            class="ci-filters__clear"
            data-cy="clear-button"
            @click="clearFilters"
          >
            <RdxIcon class="ri-close-line"></RdxIcon>
            <span>
              {{ $t("filters.clear-button", { countChosen }) }}
            </span>
          </RdxButton>
        </div>
        <div class="col-3">
          <!-- Show vehicle -->
          <RdxButton
            class="ci-filters__show"
            filled
            :disabled="!filtersOptionsChange"
            data-cy="show-button"
            @click="showChosen"
          >
            {{ $t("filters.show-vehicles-button") }}
          </RdxButton>
        </div>
      </div>
    </div>
    <portal to="publications-filters-empty-state">
      <RdxButton outline data-cy="clear-button" @click="clearFilters">
        <span>
          {{ $t("publications.empty-state.button") }}
        </span>
      </RdxButton>
    </portal>
  </div>
</template>

<script>
import CiHeading from "@/components/atoms/CiHeading";
import {
  RdxSelect,
  RdxButton,
  RdxButtonGroup,
  RdxIcon,
  RdxInputGroup
} from "@raffine/rdx-ui/lib/rdx-ui.umd";
import {
  STATES,
  ENGINES,
  AVAILABILITIES,
  YEARS,
  MILEAGE,
  PRICE
} from "./_filters";
import CiSort from "@/components/CiSort/CiSort.vue";
import CiListFilter from "@/components/molecules/CiListFilter.vue";

export default {
  name: "CiFilters",

  components: {
    CiListFilter, CiSort,
    CiHeading,
    RdxSelect,
    RdxButton,
    RdxButtonGroup,
    RdxIcon,
    RdxInputGroup
  },

  props: {
    // eslint-disable-next-line
    brands: {
      required: true,
      type: Array
    },
    // eslint-disable-next-line
    nameplates: {
      required: true,
      type: Array
    },
    // eslint-disable-next-line
    colors: {
      required: true,
      type: Array
    },
    promise: Promise,
    savedState: {
      type: Object,
      default: () => ({})
    }
  },

  data() {
    return {
      chosen: {
        state: STATES[0],
        brand: null,
        model: null,
        engine: null,
        color: null,
        availability: null,
        yearFrom: null,
        yearTo: null,
        mileageFrom: null,
        mileageTo: null,
        priceFrom: null,
        priceTo: null,
        listing: null
      },

      options: {
        states: STATES,
        brands: [],
        engines: ENGINES,
        colors: [],
        availabilities: AVAILABILITIES,
        years: YEARS,
        mileage: MILEAGE,
        price: PRICE
      },

      filtersAreOpen: true,
      queryString: null,
      mileage_unit: null,
      currency: null
    };
  },

  computed: {
    toggleClasses() {
      return {
        "ri-arrow-up-s-line": this.filtersAreOpen,
        "ri-arrow-down-s-line": !this.filtersAreOpen
      };
    },
    getListings() {
      const listings =  this.$store.getters["listings/getAll"] || [];
      return listings.map((el)=>{return {label: el.attributes.title, value: el.id}})
    },
    disableModelFilter() {
      const { brand } = this.chosen;
      return !brand || brand.length === 0 || !brand.value;
    },
    chosenFilters() {
      const chosen = Object.entries(this.chosen).filter(([, data]) => {
        return data && data.value;
      });
      return Object.fromEntries(chosen);
    },

    countChosen() {
      return Object.values(this.chosen).filter(data => {
        return data && data.value;
      }).length;
    },

    filtersOptionsChange() {
      return this.queryString !== JSON.stringify(this.chosenFilters);
    },
    nameplatesByBrand() {
      return this.chosen.brand
        ? this.nameplates.filter(nameplate => {
            return nameplate.brandId === this.chosen.brand.id;
          })
        : [];
    }
  },

  watch: {
    "chosen.brand"(newBrand, oldBrand) {
      if (
        newBrand === null ||
        (newBrand && oldBrand && newBrand.value !== oldBrand.value)
      ) {
        this.chosen.model = null;
      }
    },

    "chosen.yearFrom"() {
      this.validateFromTo("year", "To");
    },
    "chosen.yearTo"() {
      this.validateFromTo("year", "From");
    },
    "chosen.mileageFrom"() {
      this.validateFromTo("mileage", "To");
    },
    "chosen.mileageTo"() {
      this.validateFromTo("mileage", "From");
    },
    "chosen.priceFrom"() {
      this.validateFromTo("price", "To");
    },
    "chosen.priceTo"() {
      this.validateFromTo("price", "From");
    },
    chosen: {
      deep: true,
      handler(value) {
        this.$emit("change", value);
        /*
        this.$gtag.event("used", {
          event_category: "filters",
          value
        });*/
      }
    },

    savedState: {
      deep: true,
      handler(newChosen) {
        this.loadSavedState(newChosen);
      }
    }
  },

  created() {
    this.watchFiltersOptions();
  },

  mounted() {
    this.$store.dispatch("listings/fetchAll")
    this.promise.then(() => {
      this.loadSavedState(this.savedState);
      this.queryString = JSON.stringify(this.chosenFilters);
    });
    this.$http.get("/company").then(
      ({
        data: {
          data: {
            attributes: { mileage_unit, currency }
          }
        }
      }) => {
        this.mileage_unit = mileage_unit;
        this.currency = currency;
      }
    );
  },

  methods: {
    loadSavedState(savedState) {
      for (let key in savedState) {
        this.chosen[key] = savedState[key];
      }
    },

    validateFromTo(filterName, suffix) {
      const from = this.chosen[filterName + "From"];
      const to = this.chosen[filterName + "To"];
      if (from && from.value && to && to.value) {
        if (from.value > to.value) this.chosen[filterName + suffix] = null;
      }
    },

    watchFiltersOptions() {
      ["brands", "nameplates", "colors"].forEach(prop => {
        this.$watch(prop, this.setOptions.bind(this, prop), {
          immediate: true
        });
      });
    },

    setOptions(key, data) {
      this.options[key] = data;
    },
    setDefaultState() {
      this.chosen.state = STATES[0];
      this.chosen.brand = null;
      this.chosen.model = null;
      this.chosen.engine = null;
      this.chosen.color = null;
      this.chosen.availability = null;
      this.chosen.yearFrom = null;
      this.chosen.yearTo = null;
      this.chosen.mileageFrom = null;
      this.chosen.mileageTo = null;
      this.chosen.priceFrom = null;
      this.chosen.priceTo = null;
      this.queryString = JSON.stringify({});
    },

    toggleFilters() {
      this.filtersAreOpen = !this.filtersAreOpen;
    },
    setChosen(key, value) {
      this.chosen[key] = value;
    },
    isChosen(key, { value }) {
      let { value: chosenValue } = this.chosen[key];
      return {
        filled: !!(chosenValue === value),
        outline: !!(chosenValue !== value)
      };
    },

    showChosen() {
      this.queryString = JSON.stringify(this.chosenFilters);
      this.$emit("filters-search", JSON.parse(this.queryString));
    },
    clearFilters() {
      this.setDefaultState();
      this.$emit(
        "filters-clear",
        JSON.parse(JSON.stringify(this.chosenFilters))
      );
    },

    formatMileage(mileage) {
      if (this.mileage_unit) {
        let translationKey = `global.mileage_units.${this.mileage_unit}`;
        return `${mileage.label} ${this.$t(translationKey)}`;
      }
      return mileage;
    },

    formatPrice(price) {
      if (this.currency) {
        return `${price.label} ${this.currency}`;
      }
      return price;
    }
  }
};
</script>

<style lang="scss">
.ci-filters {
  position: sticky;
  z-index: 50;
  left: 0;
  margin-bottom: 1rem;
  width: 100%;
  background: $grey-1;
  border-radius: 0.5rem;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  user-select: none;

  &__toggle {
    position: absolute;
    top: 0.5rem;
    right: 1rem;
    color: $grey-7;
    padding: 0.5rem;
    cursor: pointer;
  }

  &__show {
    float: right;
  }
}
</style>
