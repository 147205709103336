<template>
  <div class="cars-inventory-view">
    <div class="rdx-sidebar__heading">
      <RdxIcon class="ri-car-line rdx-sidebar__icon rdx-icon--blue" />
      <span>CarsInventory</span>
    </div>

    <RdxTabs class="px-4">
      <RdxTabsItem
        :active="
          isCurrentRoute('CarsInventoryStock') ||
            isCurrentRoute('CarsInventoryStockID')
        "
        @click="navigateTo({ name: 'CarsInventoryStock' })"
      >
        {{ $t("navbar.stock") }}
      </RdxTabsItem>
      <RdxTabsItem
        :active="isCurrentRoute('CarsInventoryPublications')"
        @click="navigateTo({ name: 'CarsInventoryPublications' })"
      >
        {{ $t("navbar.publications") }}
      </RdxTabsItem>
      <RdxTabsItem
        :active="isCurrentRoute('CarsInventoryButtonSets')"
        @click="navigateTo({ name: 'CarsInventoryButtonSets' })"
      >
        {{ $t("navbar.buttons") }}
      </RdxTabsItem>
      <RdxTabsItem
        :active="isCurrentRoute('CarsInventoryDiscounts')"
        @click="navigateTo({ name: 'CarsInventoryDiscounts' })"
      >
        Discount
      </RdxTabsItem>
      <RdxTabsItem
        :active="isSettings"
        @click="navigateTo({ name: 'CarsInventorySettingsProfile' })"
      >
        {{ $t("navbar.settings") }}
      </RdxTabsItem>
      <portal-target class="cars-inventory-nav" name="cars-inventory-nav" />
    </RdxTabs>

    <div
      class="cars-inventory-view__inner p-4"
      :class="{ 'cars-inventory-view__inner--noscroll': noScroll }"
    >
      <CiFilters
        v-if="displayOnThisPage"
        :brands="brandsOptions"
        :nameplates="nameplatesOptions"
        :colors="colors"
        :promise="promise"
        :saved-state="filterSetting"
        @filters-search="setState"
        @filters-clear="setState"
      />

      <router-view
        :key="$router.name"
        :filters-state="filtersState"
        :promise="promise"
        @set-noscroll="setNoscroll($event)"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { RdxTabs, RdxTabsItem, RdxIcon } from "@raffine/rdx-ui/lib/rdx-ui.umd";
import CiFilters from "@/components/CiFilters/CiFilters";
import { deattribute } from "kitsu-core";

export default {
  name: "CarsInventoryView",

  components: {
    RdxTabs,
    RdxTabsItem,
    RdxIcon,
    CiFilters
  },

  data() {
    return {
      brands: [],
      nameplates: [],
      colors: [
        { label: this.$t("global.colors.white"), value: "white" },
        { label: this.$t("global.colors.black"), value: "black" },
        { label: this.$t("global.colors.silver"), value: "silver" },
        { label: this.$t("global.colors.gray"), value: "gray" },
        { label: this.$t("global.colors.red"), value: "red" },
        { label: this.$t("global.colors.green"), value: "green" },
        { label: this.$t("global.colors.bronze"), value: "bronze" },
        { label: this.$t("global.colors.blue"), value: "blue" },
        { label: this.$t("global.colors.yellow"), value: "yellow" },
        { label: this.$t("global.colors.orange"), value: "orange" }
      ],

      filtersState: {},
      promise: null,
      noScroll: false
    };
  },

  computed: {
    brandsOptions() {
      return Object.values(this.brands).map(brand => {
        return {
          label: brand.name,
          value: brand.name,
          id: brand.id
        };
      });
    },

    nameplatesOptions() {
      return Object.values(this.nameplates).map(nameplate => {
        return {
          label: nameplate.name,
          value: nameplate.name,
          id: nameplate.id,
          brandId: nameplate.relationships.brand.data.id
        };
      });
    },

    displayOnThisPage() {
      const routeNames = [
        "CarsInventoryStock",
        "CarsInventoryStockID",
        "CarsInventoryPublications"
      ];
      return !!routeNames.includes(this.$route.name);
    },

    ...mapGetters({
      filterSetting: "sortFilter/filterSetting"
    }),
    isSettings() {
      return (
        this.isCurrentRoute("CarsInventorySettingsProfile") ||
        this.isCurrentRoute("CarsInventorySettingsLocation") ||
        this.isCurrentRoute("Dictionary") ||
        this.isCurrentRoute("CarsInventorySettingsUnits")
      );
    }
  },

  created() {
    const BRANDS = this.$http.get("/brands").then(response => {
      deattribute(response.data.data);
      this.brands = response.data.data;
    });

    const NAMEPLATES = this.$http.get("/nameplates").then(response => {
      deattribute(response.data.data);
      this.nameplates = response.data.data;
    });

    this.promise = Promise.all([BRANDS, NAMEPLATES]).then(() => {
      // this.$toast.success("resources loaded");
    });
  },

  methods: {
    setState(chosen) {
      this.$store.commit("sortFilter/SET_FILTER_STATE", chosen);
    },
    isCurrentRoute(routeName) {
      const { name } = this.$route;
      return !!(name === routeName);
    },
    navigateTo(route) {
      if (route.name !== this.$route.name) {
        this.$router.push(route);
      }
    },

    setNoscroll(e) {
      this.noScroll = e;
    }
  }
};
</script>

<style lang="scss">
.cars-inventory-view {
  height: 100%;
  &__inner {
    display: block;
    width: 100%;
    height: 100%;
    max-height: calc(100vh - 163px);
    overflow: auto;
    &--noscroll {
      overflow: hidden;
    }
  }
}

.cars-inventory-nav {
  position: absolute;
  right: 1.5rem;
}

.ci-heading-with-icon {
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  color: $grey-9;

  i {
    color: $blue-7;
  }
}
</style>
