<template>
  <div class="ci-sort">
    <RdxDropdown side="left" class="mr-3">
      <template #trigger>
        <RdxButton outline>
          <span>{{ $t(`sort.options.${sortSelected.key}`) }}</span>
          <RdxIcon icon="ri-arrow-down-s-line rdx-icon--16" />
        </RdxButton>
      </template>
      <div class="ci-sort__items">
        <div
          v-for="option of options"
          :key="`sort-option-${option.value}`"
          class="ci-sort__item"
          :class="{
            'ci-sort__item--selected': option.value === sortSelected.value
          }"
          @click="chosen.sort = option.value"
        >
          {{ $t(`sort.options.${option.key}`) }}
        </div>
      </div>
    </RdxDropdown>
    <RdxInput
      :placeholder="$t('sort.search-vin-input-placeholder')"
      :value="chosen.vin_start"
      type="search"
      class="ci-sort__vin-search mr-3 pr-3 mb-0"
      @input="onVinInput"
    >
      <template #prefix>
        <RdxIcon icon="ri-search-line" />
      </template>
    </RdxInput>
  </div>
</template>

<script>
import debounce from "@/utils/debounce";
import {
  RdxInput,
  RdxIcon,
  RdxDropdown,
  RdxButton
} from "@raffine/rdx-ui/lib/rdx-ui.umd";
import { OPTIONS } from "./_filter";

export default {
  name: "CiSort",
  components: {
    RdxInput,
    RdxIcon,
    RdxDropdown,
    RdxButton
  },

  props: {
    savedState: {
      type: Object,
      default: () => ({})
    }
  },

  data() {
    return {
      options: OPTIONS,
      chosen: {
        vin_start: null,
        sort: "-created_at"
      },
      debounceEmitUnwatch: null
    };
  },

  computed: {
    sortSelected() {
      return (
        this.options.find(option => option.value === this.chosen.sort) ||
        this.options[0]
      );
    }
  },

  watch: {
    savedState: {
      deep: true,
      immediate: true,
      handler(newChosen) {
        for (let key in newChosen) {
          this.$set(this.chosen, key, newChosen[key]);
        }
      }
    }
  },

  mounted() {
    this.debounceEmitUnwatch = this.$watch(
      "chosen",
      debounce(newChosen => {
        this.$emit("change", newChosen);
      }, 500),
      { deep: true }
    );
  },

  beforeDestroy() {
    if (this.debounceEmitUnwatch) this.debounceEmitUnwatch();
  },
  methods: {
    onVinInput($event) {
      this.$set(this.chosen, "vin_start", $event);
    }
  }
};
</script>

<style lang="scss">
.ci-sort {
  display: flex;
  flex-direction: row;

  .rdx-dropdown {
    min-width: 160px;

    &__trigger,
    &__menu {
      width: auto;
      min-width: 100%;
    }
    &__trigger {
      .rdx-button {
        width: 100%;
        justify-content: space-between;
      }
    }
  }

  &__vin-search {
    border-right: 1px solid $grey-4;
    width: 260px;
  }

  &__items {
    z-index: 4;
  }
  &__item {
    cursor: pointer;
    white-space: nowrap;
    padding: 5px 12px;
    color: $grey-8;
    font-size: 14px;
    line-height: 20px;

    &:hover {
      background: #e6f7ff;
    }

    &--selected {
      background: #fafafa;
      font-weight: 600;
    }
  }
}
</style>
