<template>
  <div class="ci-list-filter">
    <RdxDropdown side="right" prevent-closing>
      <template #trigger>
        <RdxButton outline :disabled="options.length === 0">
          <span>{{
            $t("publications.list-filter.show-lists-placeholder")
          }}</span>
          <RdxIcon icon="ri-arrow-down-s-line rdx-icon--16" />
        </RdxButton>
      </template>
      <div class="ci-list-filter__dropdown-menu">
        <div class="ci-list-filter__dropdown-header">
          <p class="ci-list-filter__dropdown-title">
            {{ $t("publications.list-filter.header") }}
          </p>
          <p class="ci-list-filter__dropdown-description">
            {{ $t("publications.list-filter.description") }}
          </p>
        </div>
        <div class="ci-list-filter__dropdown-content">
          <div class="ci-list-filter__dropdown-search">
            <div class="ci-list-filter__dropdown-search-wrapper">
              <RdxInput
                v-model="searchString"
                :placeholder="
                  $t('publications.list-filter.search-input-placeholder')
                "
              />
              <RdxIcon icon="ri-search-line" />
            </div>
          </div>
          <div class="ci-list-filter__items">
            <p class="ci-list-filter__items-header">
              {{
                searchString
                  ? $t("publications.list-filter.results")
                  : $t("publications.list-filter.lists")
              }}
            </p>
            <div
              v-for="(el, id) in filteredLists"
              :key="`list-${id}`"
              class="ci-list-filter__item"
              data-cy="ci-list-filter__item"
            >
              <RdxCheckbox
                :value="isChecked(el.id)"
                data-cy="ci-list-filter__checkbox"
                @change="toggleList(el)"
              >
                <template #label>
                  <div class="ci-list-filter__item-label">
                    <span class="ci-list-filter__item-name">
                      {{ el.attributes.title }}
                    </span>
                    <span class="ci-list-filter__item-company">
                      {{ el.attributes.owner_name }}
                    </span>
                  </div>
                </template>
              </RdxCheckbox>
            </div>
            <div
              v-if="filteredLists.length === 0"
              class="ci-list-filter__empty-state"
            >
              <RdxIcon icon="ri-file-paper-line" />
              <p class="ci-list-filter__empty-state-header">
                {{ $t("publications.list-filter.empty-state.header") }}
              </p>
              <p class="ci-list-filter__empty-state-description">
                {{ $t("publications.list-filter.empty-state.description") }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </RdxDropdown>
  </div>
</template>

<script>
import {
  RdxDropdown,
  RdxButton,
  RdxInput,
  RdxIcon,
  RdxCheckbox
} from "@raffine/rdx-ui/lib/rdx-ui.umd";

export default {
  name: "CiListFilter",
  components: {
    RdxDropdown,
    RdxButton,
    RdxInput,
    RdxIcon,
    RdxCheckbox
  },

  props: {
    value: {
      type: Array,
      default: () => []
    },
    options: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      searchString: null
    };
  },

  computed: {
    filteredLists() {
      if (!this.searchString) return this.options;
      let filteredOptions = this.options.filter(el => {
        const searchString = this.searchString.toLowerCase();
        const title = el.attributes.title.toLowerCase();
        const owner_name = el.attributes.owner_name.toLowerCase();
        return (
          title.includes(searchString) || owner_name.includes(searchString)
        );
      });

      return filteredOptions;
    }
  },

  methods: {
    toggleList(listing) {
      let elements = [];
      for (let el of this.value) {
        elements.push(el);
      }

      const index = elements.map(element => element.id).indexOf(listing.id);
      if (index > -1) {
        elements.splice(index, 1);
      } else {
        elements.push(listing);
      }

      this.$emit("input", elements);
    },

    isChecked(id) {
      return this.value.map(listing => listing.id).includes(String(id));
    }
  }
};
</script>

<style lang="scss">
.ci-list-filter {
  &__dropdown {
    &-menu {
      min-width: 250px;
    }
    &-header {
      border-bottom: 1px solid $grey-4;
    }
    &-header,
    &-content {
      padding: 12px 16px;
    }

    &-title {
      font-weight: bold;
      color: $grey-9;
    }
    &-description {
      color: $grey-7;
    }
    &-search {
      &-wrapper {
        position: relative;
      }
      .rdx-input {
        &__input {
          padding: 1px 1px 1px 24px;
        }
      }
      .rdx-icon {
        color: $grey-6;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 7px;
      }
    }
  }

  &__items {
    min-height: 220px;
    max-height: 300px;
    overflow-y: scroll;
    &-header {
      font-size: 12px;
      color: $grey-7;
    }
  }

  &__item {
    padding: 6px 0;
    .rdx-checkbox {
      margin: 0;
    }
    &-name,
    &-company {
      display: block;
    }

    &-name {
      color: $grey-9;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 150px;
    }
    &-company {
      font-size: 12px;
      color: $grey-7;
    }
  }

  &__empty-state {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding-top: 35px;
    .rdx-icon {
      font-size: 48px;
      line-height: 48px;
      color: $grey-5;
    }

    &-header {
      font-size: 14px;
      line-height: 20px;
      font-weight: bold;
      color: $grey-9;
    }

    &-description {
      font-size: 12px;
      line-height: 17px;
      color: $grey-7;
    }
  }
}
</style>
