/**
 * State
 */
export const STATES = [
  { value: null, label: "All" },
  { value: "new", label: "New" },
  { value: "used", label: "Used" },
  { value: "approved", label: "Approved" }
];

/**
 * Availablity
 */
export const AVAILABILITIES = [
  { value: "stock", label: "In Stock" },
  { value: "reserved", label: "Reserved" },
  { value: "in_production", label: "In Production" },
  { value: "available_soon", label: "Available Soon" },
  { value: "delivery", label: "In Delivery" },
  { value: "sold", label: "Sold" }
];

/**
 * Engine
 */
export const ENGINES = [
  { value: "petrol", label: "Petrol" },
  { value: "diesel", label: "Diesel" },
  { value: "bev", label: "Electric (BEV)" },
  { value: "mhev", label: "Mild hybrid (MHEV)" },
  { value: "phev", label: "Plug-in hybrid (PHEV)" }
];

/**
 * Year
 */
let yearsArray = [];
for (let i = 1900; i <= new Date().getFullYear() + 1; i++) {
  yearsArray.push(i);
}
yearsArray.reverse();
export const YEARS = {
  from: [
    ...yearsArray.map(year => {
      return { value: year, label: year };
    })
  ],
  to: [
    ...yearsArray.map(year => {
      return { value: year, label: year };
    })
  ]
};

/**
 * Mileage
 */
export const MILEAGE = {
  from: [
    { value: 10000, label: "10 000" },
    { value: 100000, label: "100 000" },
    { value: 250000, label: "250 000" },
    { value: 500000, label: "500 000" },
    { value: 1000000, label: "1 000 000" }
  ],
  to: [
    { value: 10000, label: "10 000" },
    { value: 100000, label: "100 000" },
    { value: 250000, label: "250 000" },
    { value: 500000, label: "500 000" },
    { value: 1000000, label: "1 000 000" }
  ]
};

/**
 * Price
 */
export const PRICE = {
  from: [
    { value: 10000, label: "10 000" },
    { value: 25000, label: "25 000" },
    { value: 50000, label: "50 000" },
    { value: 75000, label: "75 000" },
    { value: 100000, label: "100 000" },
    { value: 250000, label: "250 000" },
    { value: 500000, label: "500 000" },
    { value: 750000, label: "750 000" },
    { value: 1000000, label: "1 000 000" }
  ],
  to: [
    { value: 10000, label: "10 000" },
    { value: 25000, label: "25 000" },
    { value: 50000, label: "50 000" },
    { value: 75000, label: "75 000" },
    { value: 100000, label: "100 000" },
    { value: 250000, label: "250 000" },
    { value: 500000, label: "500 000" },
    { value: 750000, label: "750 000" },
    { value: 1000000, label: "1 000 000" }
  ]
};
